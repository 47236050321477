html {
    position: relative;
    min-height: 100%;
}
body {
	background-color: $background-color;
	color: $primary-text-color;

	margin: 0 0 $footer-height + 100;
	@media (min-width: $screen-sm) {
	margin: 0 0 $footer-height;
	}

	animation: softload 1s ease;
}

.page-wrap {
	margin-top: 10%;
	animation: slideInUp .8s ease;

	h1.page-title {
		text-transform: uppercase;
		animation: slideInLeft .8s ease;
	}
}

.heading {
	display: block;
	color: $clrs-black;
	text-align: center;

	span {
		color: $clrs-purple;
	}
}

.greeting { animation: bounceIn 1s ease; }
.title { animation: slideInUp 1s ease; }

.description {
	margin-top: 5%;
	animation: bounceInUp 1s ease;
}

.descriptor {
	// background-color: aqua;
	margin-bottom: 10px;
	padding: 10px;

	h1 {
		font-family: $josefinslab;
		text-align: center;
		margin: 0px;
		cursor: default;

		font-size: 15px;
  		@media (min-width: $screen-sm) {
		font-size: 25px;
  		}

		&:before {
			content: "{ ";
			transition: all .5s ease;
			color: $clrs-purple;
		}	

		&:after {
			content: " }";
			transition: all .5s ease;
			color: $clrs-purple;
		}
	}

	section {
		width: 50%;
		@include center-block();
		padding: 0px 10px;

		height: 0px;
		overflow: hidden; 
		transition: all .5s ease;
	}
}

.descriptor:hover {

	section {
		height: 100px;
		padding: 10px 10px;
	}

}

.form-control {
	border: 0px solid $input-border;
	border-left: 7px solid $primary-theme-color;
	// border-bottom: 2px solid $input-border;
	@include box-shadow(none);
}

textarea.form-control {
	height: 130px;

	@media (min-width: $screen-sm) {
		height: auto;
	}
}

footer {
	// background-color: blue;
	display: flex;
	flex-flow: row wrap;

	position: absolute;
    left: 0;
    bottom: 0;
    
    width: 100%;
	height: $footer-height + 100;
	@media (min-width: $screen-sm) {
	height: $footer-height;
	}


	.footer-item {
		// background-color: green;
		transition: color 1s ease;
		color: $clrs-gray;
		
		flex: 1 1 100%;
		text-align: center;

		// max-width: 100%;
		@media (min-width: $screen-sm) {
			flex: 1 1 33.33%;
			max-width: 50%;
			text-align: left;
		}

		// flex: 1 1 33.33%;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		

		.center {
			// background-color: orange;
			flex: 1 1 45%;
			max-height: 2em;
			line-height: 2em;
			padding: 0px 1em;

			a {
				color: $clrs-gray;
				text-decoration: none;
				transition: all .5s ease;

				&:hover {
					color: $primary-theme-color;
				}

			}
		}
	}

	div.social {
		text-align: center;
		font-size: 2em;

		@media (min-width: $screen-sm) {
			font-size: inherit;
		}

		i {
			color: $clrs-gray;
			transition: color 1s ease;
		}

		&:hover i {
			color: $clrs-black;
		}

	}

	div.lincoln {
		cursor: default;

		@media (min-width: $screen-sm) {
			text-align: right;
		}

		i {
			color: $clrs-gray;
			transition: color 1s ease;
		}

		// &:hover {
		// 	color: $clrs-black;
		// 	i { color: $clrs-red; }
		// }
	}

}



