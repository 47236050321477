/* _buttons.scss */

// EXAMPLE: button-variant(fontcolor, background, border);

.btn-action {
	@include button-variant( rgba(255,255,255,1), rgba(0,0,0,1), rgba(0,0,0,0) );

	// font-family: $TexRegular;
	font-family: $lato;
	font-size: 1.5em;
	padding: 10px 25px;
	// border-width: 2px;
	border-style: solid;
	border-radius: 0px;

	box-shadow: none;
	transition: all .5s ease;

	&:hover {
		@include button-variant(rgba(255,255,255,1), $primary-theme-color, rgba(0,0,0,0) );
	}
}