// Keyframe animation
@-webkit-keyframes softload {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes softload {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes load-from-left {
  0%   { transform: translate(-1000px,0px); }
  100% { transform: translate(0,0); }
}
@keyframes load-from-left {
  0%   { transform: translate(-1000px,0px); }
  100% { transform: translate(0,0); }
}

@-webkit-keyframes load-from-bottom {
  0%   { transform: translate(0px,25px); opacity: 0;}
  100% { transform: translate(0,0); opacity: 1;}
}
@keyframes load-from-bottom {
  0%   { transform: translate(0px,25px); opacity: 0;}
  100% { transform: translate(0,0); opacity: 1;}
}

@-webkit-keyframes load-from-top {
  0%   { transform: translate(0px,-25px); opacity: 0;}
  100% { transform: translate(0,0); opacity: 1;}
}
@keyframes load-from-top {
  0%   { transform: translate(0px,-25px); opacity: 0;}
  100% { transform: translate(0,0); opacity: 1;}
}




@-webkit-keyframes featured-load {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes featured-load {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}