// Colors
$white: #ffffff;
$black: #000000;

// $light-black: #232323;

// $gray-light: lighten(#000, 60%) !default;   // #999
// $gray-lighter: lighten(#000, 93.5%) !default; // #eee


// Cool
$clrs-aqua:  #7FDBFF;
$clrs-blue:  #0074D9;
$clrs-navy:  #001F3F;
$clrs-teal:  #39CCCC;
$clrs-green: #2ECC40;
$clrs-olive: #3D9970;
$clrs-lime:  #01FF70;

// Warm
$clrs-yellow:  #FFDC00;
$clrs-orange:  #FF851B;
$clrs-red:     #FF4136;
$clrs-fuchsia: #F012BE;
$clrs-purple:  #B10DC9;
$clrs-maroon:  #85144B;

// Gray Scale
$clrs-white:  #fff;
$clrs-silver: #ddd;
$clrs-gray:   #aaa;
$clrs-black:  #111;

// Relative Assets Paths
$img-path:                    "../img/";

$theme-font-path:             "../fonts";
$fa-font-path:                $theme-font-path + "/fontawesome";

$lato: 'Lato', sans-serif;
$josefinslab: 'Josefin Slab', serif;

$background-color: $clrs-white;
$background-color-alt: $white;

$primary-theme-color: $clrs-purple;

$primary-text-color: $clrs-black;
$primary-text-color-alt: $black;

$accent-color: $primary-text-color;

$footer-height: 50px;


$headings-font-family:    $lato;
$headings-font-weight:    900;
$headings-line-height:    1.1;
// $headings-color:          inherit !default;


$input-bg:                       rgba($black,0.05);
$input-color:                    $black;
$input-border:                   $black;
$input-border-radius:            0;
$input-border-focus:             $primary-theme-color;